<template>
  <v-card
    :loading="loading"
    style="margin-top:1em;max-width:350px; padding-bottom:1em"
    class="mx-auto"
    elevation="1"
    raised
    color="rgba(122,122,122,0.1)"
  >
    <v-row style="padding-top:1em;">
      <div class="pt-4">
        <v-icon
          v-if="showDate"
          :class="{ 'red--text': getTimeAgo() > 2 }"
          class="pl-8"
        >
          mdi-clock
        </v-icon>
        <span
          :class="{ 'red--text': getTimeAgo() > 2 }"
          class="subheading pl-2 text--darken-3"
          >{{ formatTimeAgo() }}</span
        >
        <span class="mr-4"></span>
      </div>
    </v-row>
    <v-list-item three-line style="padding:1em 2em">
      <v-list-item-content v-if="call && call.user">
        <div class="overline mb-4"></div>
        <v-list-item-title class="headline mb-1 flex">
          {{ call.user.name }}
          <span class="mt-2" v-html="call.mobile ? phoneIcon : PcIcon"></span>
          <span style="font-size:14px">{{
            call.user.id
              ? "(id: " + call.user.id + ")"
              : "(id: " + call.user + ")"
          }}</span>
        </v-list-item-title>
        <v-list-item-subtitle>{{ call.user.email }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-card-actions style="margin: 0 1.5em 2em">
      <v-row align="center" justify="space-between">
        <v-btn
          v-if="isItActive(call.activeTime)"
          style="padding: 0 1em; margin-left:0.5em"
          color="#014C40"
          @click="$emit('answerCall', call, itemKey)"
          rounded
          :disabled="loading"
        >
          <div style="color:#fff">
            Pradėti pokalbį
          </div>
        </v-btn>
        <v-btn
          @click="$emit('openRemoveDialog')"
          v-bind="attrs"
          v-on="on"
          :disabled="loading"
          icon
        >
          <v-icon color="#C33C22">mdi-phone-off-outline</v-icon>
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { db } from "../fire.js";
import { lt } from "date-fns/locale";
import {
  differenceInMinutes,
  differenceInSeconds,
  formatDistanceToNow
} from "date-fns";
import { mapState } from "vuex";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    call: {
      type: Object
    },
    itemKey: {
      type: String
    },
    currentCallAnswerKey: {
      type: String
    }
  },
  data() {
    return {
      showDate: true,
      phoneIcon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-smartphone"><rect x="5" y="2" width="14" height="20" rx="2" ry="2"></rect><line x1="12" y1="18" x2="12.01" y2="18"></line></svg>',
      PcIcon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-monitor"><rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect><line x1="8" y1="21" x2="16" y2="21"></line><line x1="12" y1="17" x2="12" y2="21"></line></svg>'
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      user: state => state.user,
      callerID: state => state.callerID,
      cartContent: state => state.cartContent,
      operators: state => state.operators,
      products: state => state.products,
      operator: state => state.operator,
      token: state => state.token
    }),
    getTimeAgoInSeconds() {
      return differenceInSeconds(
        Date.now(),
        new Date(parseInt(this.call.timestamp))
      );
    }
  },
  methods: {
    updateDate() {
      this.showDate = false;
      this.$nextTick(() => {
        this.showDate = true;
        this.$nextTick(() => {});
      });
    },
    formatTimeAgo() {
      if (!this.call.timestamp) return;
      return formatDistanceToNow(new Date(parseInt(this.call.timestamp)), {
        includeSeconds: true,
        locale: lt
      });
    },
    isItActive(timestamp) {
      if (this.getTimeAgoInSeconds < 20) return true;
      const active = differenceInSeconds(
        Date.now(),
        new Date(parseInt(timestamp))
      );
      if (
        !timestamp &&
        differenceInMinutes(
          Date.now(),
          new Date(parseInt(this.call.timestamp))
        ) > 3
      ) {
        db.ref(`signaling/${this.itemKey}`).remove();
      }
      if (active > 60) {
        db.ref(`signaling/${this.itemKey}`).remove();
      }
      return active < 20;
    },
    getTimeAgo() {
      return differenceInMinutes(
        Date.now(),
        new Date(parseInt(this.call.timestamp))
      );
    }
  }
};
</script>

<style></style>
