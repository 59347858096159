<template>
  <v-sheet
    :color="`grey lighten-2`"
    style="height:93vh;justify-content:center;align-items:center;display:flex;overflow:hidden"
    align="center"
  >
    <div style="width:100%">
      <v-skeleton-loader
        class="mx-auto"
        max-width="280"
        type="card"
      ></v-skeleton-loader>
      <div class="mt-2" style="width:90%">Laukiame klientų skambučių</div>
    </div>
  </v-sheet>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style></style>
