<template>
  <div>
    <div v-if="calls">
      <callerCard
        :loading="loadingKey == key"
        :currentCallAnswerKey="currentCallAnswerKey"
        :key="key"
        :itemKey="key"
        :call="call"
        @answerCall="answerCall"
        v-for="(call, key) of calls"
        @openRemoveDialog="openRemoveDialog(key)"
      />
    </div>

    <div v-else-if="!calls">
      <skeletonCallWait />
    </div>

    <reallyDropTheCall
      v-if="showDropCall"
      @close="showDropCall = false"
      @remove="remove"
    />
  </div>
</template>

<script>
import { db } from "../fire.js";
import callerCard from "../components/callerCard";
import { mapState } from "vuex";
import reallyDropTheCall from "../components/reallyDropTheCall";
import skeletonCallWait from "../components/skeletonCallWait";
var Peer = require("simple-peer");

export default {
  components: {
    callerCard,
    skeletonCallWait,
    reallyDropTheCall,
  },
  data() {
    return {
      showDropCall: false,
      loadingKey: null,
      iceServers: null,
      facingMode: "user",
      currentCallAnswerKey: null,
      stream: null,
    };
  },
  mounted() {
    this.getIceServers();
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      peer: (state) => state.peer,
      vibrateInterval: (state) => state.vibrateInterval,
      currentStream: (state) => state.currentStream,
      currentCaller: (state) => state.currentCaller,
      currentCallKey: (state) => state.currentCallKey,
      currentVideoTrack: (state) => state.currentVideoTrack,
      calls: (state) => state.calls,
      callerID: (state) => state.callerID,
      operators: (state) => state.operators,
      products: (state) => state.products,
      operator: (state) => state.operator,
      token: (state) => state.token,
    }),
  },
  methods: {
    openRemoveDialog(key) {
      this.currentCallAnswerKey = key;
      this.showDropCall = true;
    },

    remove() {
      this.showDropCall = false;
      this.clearVibrate();
      this.removeFromDb();
    },

    removeFromDb() {
      db.ref(`signaling/${this.currentCallAnswerKey}`).update({
        message: "Šiuo metu visi operatoriai užimti. Badykite kiek vėliau",
      });
      setTimeout(() => {
        db.ref(`signaling/${this.currentCallAnswerKey}`).remove();
      }, 1000);
    },

    sendSignalToDb(signal) {
      db.ref(`signaling/${this.currentCallAnswerKey}`).update({
        signal_answer: signal,
        operator: this.operator,
      });
    },

    clearVibrate() {
      clearInterval(this.vibrateInterval);
    },

    async answerCall(call, key) {
      this.$store.commit("toggleLoadingOverlay", true);
      this.$store.commit("setCurrentVideoTrack", null);
      this.$store.commit("setCurrentStream", null);
      this.$store.commit("removeAllStreams", null);
      this.clearVibrate();

      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: { ideal: 1400 },
            height: { ideal: 800 },
            facingMode: "user", // environment, user
          },
          audio: true,
        })
        .then(this.gotMedia)
        .then(() => {
          setTimeout(() => {
            this.connectClient(call, key);
          }, 1000);
        })
        .catch(() => {});
    },

    connectClient(call, key) {
      const self = this;
      this.loadingKey = key;
      const signal_offer = call.signal_offer;
      this.peer.signal(signal_offer);
      this.$store.commit("setCurrentCaller", call.user);
      this.$store.commit("setCurrentCallerDeviceID", call.deviceID);
      db.ref(`userCart/${this.callerID}/items`).on("value", function(snapshot) {
        self.$store.commit("setCartItemsCount", snapshot.numChildren());
        self.$store.commit("updateCartContent", snapshot.val());
      });
      this.currentCallAnswerKey = key;
    },

    initMyVideo() {
      const video = document.querySelector("#my-stream-video");
      video.srcObject = this.peer.streams[0];
      video.play();
    },

    async getIceServers() {
      // const result = await this.$root.axiosInstance("getIceServers");
      // this.iceServers = result.data.iceServers;
      this.iceServers = [
        {
          urls: "stun:stun2.kotas.lt:3478",
        },
        {
          urls: "stun:stun.l.google.com:19302",
        },
        {
          urls: "turn:turn2.kotas.lt:3478",
          username: "kotas",
          credential: "webrtc",
        },
      ];
    },

    initStream(stream) {
      var video = document.querySelector("#video-stream");
      if ("srcObject" in video) {
        video.srcObject = stream;
      } else {
        video.src = window.URL.createObjectURL(stream); // for older browsers
      }
      this.$store.commit("setUserStream", stream);
      this.initMyVideo();
      video.play();
    },

    pauseMyVideo() {
      const video = document.querySelector("#my-stream-video");
      if (video) {
        video.pause();
      }
    },

    async gotMedia(stream) {
      const self = this;
      const iceServers = this.iceServers;
      const peer = await new Peer({
        config: {
          iceServers,
        },
        stream: stream,
        trickle: false,
      });

      this.$store.commit("setCurrentStream", stream);
      this.$store.commit("addStreamToTrack", stream);
      this.$store.commit("setCurrentPeer", peer);

      this.peer.on("error", (err) => console.log("error", err));

      this.peer.on("signal", (data) => {
        self.sendSignalToDb(JSON.stringify(data));
      });

      this.peer.on("stream", (stream) => {
        self.$store.commit("setVideoStreamStatusOn", true);
        setTimeout(() => {
          self.initStream(stream);
          self.$store.commit("toggleLoadingOverlay", false);
        }, 1000);
      });

      this.peer.on("connect", () => {
        self.peer.send("whatever" + Math.random());

        db.ref(`signaling/${self.loadingKey}`).update({
          operator: self.operator,
        });

        db.ref(`signaling/${self.loadingKey}`).once("value", function(
          snapshot
        ) {
          const caller = snapshot.val();
          caller.userStreamOn = true;
          const pushResult = db
            .ref(`currentCalls/${self.userID}/${self.operator.id}`)
            .push(caller)
            .getKey();
          self.$store.commit("setCurrentCallKey", pushResult);
          db.ref(`signaling/${self.loadingKey}`).set({
            currentCallKey: pushResult,
          });
        });

        setTimeout(() => {
          db.ref(`signaling/${self.loadingKey}`).remove();
        }, 3000);
      });

      this.peer.on("data", (data) => {
        console.log("operator received received data: " + data);
      });

      this.peer.on("close", () => {
        self.$root.notify("Video ryšys buvo nutrauktas", "error");
        self.pauseMyVideo();
        if (self.currentVideoTrack) {
          self.currentVideoTrack.stop();
        }
        setTimeout(() => {
          self.peer.destroy();
          self.$store.commit("setVideoStreamStatusOn", false);
          self.$store.commit("setCurrentCaller", null);
        }, 0);
        self.peer.streams[0].getTracks().forEach((track) => track.stop());
        db.ref(
          `currentCalls/${self.user.id}/${self.operator.id}/${self.currentCallKey}`
        ).remove();
      });
    },
  },
};
</script>

<style></style>
