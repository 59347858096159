<template>
  <v-dialog v-model="dialog" width="300px">
    <v-card>
      <v-card-title class="headline">
        Tikrai?
      </v-card-title>
      <v-card-text
        >Skambinantysis matys pranešimą "šiuo metu visi operatoriai užimti.
        Bandykite kiek vėliau"</v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="$emit('close')">
          Persigalvojau
        </v-btn>
        <v-btn color="red lighten-2" text @click="$emit('remove')">
          Gerai
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
    };
  },
};
</script>

<style></style>
